// hosted urls
let apis = {
  // non-aws
  // BASE_URL: "http://142.132.181.230:8000/api/v1/",

  // ngrok base url 
  // BASE_URL:'https://4c41-2405-201-101d-386b-28d1-1cc9-2b7a-749a.ngrok-free.app/api/v1/',


  // FUDOMAX URL 
  BASE_URL: 'https://api.fudomax.xyz/api/v1/',

  // this url is used specifically for rules api 
  RULE_API_BASE_URL: 'https://rule-api-test.fudomax.xyz/api/v1',

  SAMPLE_EXCEL_URL :"https://app-test.fudomax.xyz/ftp/clientinforexcelformat.xls",

  // myathira url 
  // BASE_URL: 'https://api.myathira.com/api/v1/',

  // this url is used specifically for rules api 
  // RULE_API_BASE_URL: 'https://rule-api.myathira.com/api/v1',

  // SAMPLE_EXCEL_URL: "https://app.myathira.com/ftp/clientinforexcelformat.xls",


  // live socket url 
  // SOCKET_BASE_URL: "http://142.132.181.230:800",


  // live socket FUDOMAX URL 
  SOCKET_BASE_URL: "https://api.fudomax.xyz",


  // ngrok socket url 
  // SOCKET_BASE_URL: "https://b2c6-2405-201-101d-3846-7478-76c2-53ad-d909.ngrok-free.app",

  FRONTED_URL: "http://142.132.181.230/",

  // aws
  // BASE_URL: "http://13.232.194.103:8000/api/v1/",
  // SOCKET_BASE_URL: "http://13.232.194.103:8001",
  // FRONTED_URL: "http://ontym.s3-website.ap-south-1.amazonaws.com/",

  // ACT
  ACT_DETAILS: (act_id: number) => `act/${act_id}`,
  CREATE_UPDATE_ACT: "act/create-update",

  // PROVISION
  DELETE_PROVISION: (provision_id: number) =>
    `provision/delete/${provision_id}`,

  // SKILL
  GET_SKILLS: "skill",
};

export default apis;
